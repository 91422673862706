import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';

import mq from '../styles/mq';

import { ThemeType } from '../styles/theme';

const MentionsLegales = () => {
  const { color } = useTheme<ThemeType>();

  return (
    <Layout footer>
      <SEO title="Mentions légales" />
      <Container>
        <section
          css={css`
            padding: 150px 0;
            min-height: 100vh;
          `}
        >
          <h1
            css={css`
              color: ${color.accent};
              font-size: 30px;
              font-weight: 900;
              margin-bottom: 50px;
              text-align: center;

              ${mq(`xs`)} {
                font-size: 40px;
              }

              ${mq(`sm`)} {
                font-size: 50px;
              }

              ${mq(`md`)} {
                font-size: 60px;
                text-align: left;
              }
            `}
          >
            Mentions légales
          </h1>
          <ul
            css={css`
              li {
                color: ${color.accent};
                font-size: 20px;
                margin-bottom: 10px;
              }
            `}
          >
            <li>ASBL la 3D</li>
            <li>Rue de Blanmont (NSV) 7</li>
            <li>1457 Walhain-Saint-Paul</li>
            <li>BCE : 0748.433.588</li>
            <li>E-mail : la3d.beer@gmail.com</li>
            <li>Site internet : www.la3d.be // http://www.la3dbeer.be/</li>
          </ul>
        </section>
      </Container>
    </Layout>
  );
};

export default MentionsLegales;
